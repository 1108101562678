import React from 'react';
import Header from "../../../commons/Header/Header";
import Footer from "../../../commons/Footer/Footer";
import PartnerForm from "../../../commons/PartnerForm/PartnerForm";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const PartnerGficPage = (props) => {

  return (
    <>
      <Header en='/partner/greatwayfinancial' fr='/contact-us/reviews' lang='en' />
      <Container className="mainDiv partnerFormPage">
        <Row className="">
          <Col xl={12} className="content">
            <h1>Greatway Financial Information Center</h1>
            <Container>
              <Row className="">
                <Col xl={12}>
                  <div className="bannerInner header-online-training"></div>
                  <br />
                 <h2><strong>Welcome to the Travelance Information Centre for Greatway Financial Group Brokers</strong></h2>
                 <p>On this page, you will find the tools to help you get started selling Travelance products.</p>
                </Col>
                <Col xl={8} className="">
                  <PartnerForm heading="Partner with Travelance" mga="gfic" />
                  <br />
                  <div id="internationalstudentplan">
                <h2>Online Training</h2>
                <a href="/partner/online-training-sessions/" target="_blank" rel="noopener noreferrer">Live Online Training Session</a> - Register for our online training sessions to learn about our products and services. Each week, our
                team members lead interactive, lively, and information-packed sessions, with small class sizes, and a team member can answer all your questions.

                <h3><span className="highlight">Emergency Medical Insurance for Visitors to Canada</span></h3>
                Protection for visitors to Canada against unforeseen medical emergencies is a must as they are not covered under Canada’s health care system. After attending this session, you will have an in-depth knowledge about Visitors
                to Canada Emergency Medical Insurance plans. Please register for one of the following ‘Emergency Medical Insurance for Visitors to Canada’ sessions:
                <p style={{fontSize:'14px',color:'#495677'}}>Please register for <span style={{textDecoration: 'underline'}}>one</span> of the following ‘Emergency Medical Insurance for Visitors to Canada’ sessions:</p>
                <table style={{height:'150px'}} border="1" width="100%">
                    <tbody>
                        <tr className="bg-color txt-center">
                            <td style={{textAlign:'center',backgroundColor:'#e8e8e8'}} width="37%"><strong>Date &amp; Time (MT)</strong></td>
                            <td style={{textAlign:'center',backgroundColor:'#e8e8e8'}} width="29%"><strong>Presenter</strong></td>
                            <td style={{textAlign:'center',backgroundColor:'#e8e8e8'}} width="34%"><strong>Registration</strong></td>
                        </tr>
                        <tr className="alt">
                          <td style={{textAlign:'center'}}>April 15, 2025 4:00 pm (MT)</td>
                          <td style={{textAlign:'center'}}>Kristina van der Wal</td>
                          <td style={{textAlign:'center'}}>
                            <span style={{textDecoration: 'underline'}}>
                              <span style={{color:'#cc4037',textDecoration:'underline'}}>
                                <span style={{color:'#cc4037',textDecoration:'underline'}}>
                                  <a href="https://travelanceinc.clickmeeting.com/greatway-financial-emergency-medical-insurance-for-visitors-to-canada-apr-15-/register" target="_blank" rel="noopener noreferrer">Click to Register</a>
                                </span>
                              </span>
                            </span>
                          </td>
                        </tr>

                        <tr className="alt">
                          <td style={{textAlign:'center'}}>November 18, 2025 4:00 pm (MT)</td>
                          <td style={{textAlign:'center'}}>Kristina van der Wal</td>
                          <td style={{textAlign:'center'}}>
                            <span style={{textDecoration: 'underline'}}>
                              <span style={{color:'#cc4037',textDecoration:'underline'}}>
                                <span style={{color:'#cc4037',textDecoration:'underline'}}>
                                  <a href="https://travelanceinc.clickmeeting.com/greatway-financial-emergency-medical-insurance-for-visitors-to-canada-nov-18-/register" target="_blank" rel="noopener noreferrer">Click to Register</a>
                                </span>
                              </span>
                            </span>
                          </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />

            <div id="travelmedical">
                <h3><span className="highlight">International Student Plan</span></h3>
                Learn about insurance options for an international student who is enrolled in a school within Canada, which also provides coverage to an accompanying spouse or dependent children of the student residing in Canada.
                <p style={{fontSize:'14px',color:'#495677'}}>Please register for <span style={{textDecoration: 'underline'}}>one</span> of the following ‘International Student Plan’ sessions:</p>
                <table style={{height:'100px'}} border="1" width="100%">
                    <tbody>
                        <tr className="bg-color txt-center">
                            <td style={{textAlign:'center',backgroundColor:'#e8e8e8'}} width="37%"><strong>Date &amp; Time (MT)</strong></td>
                            <td style={{textAlign:'center',backgroundColor:'#e8e8e8'}} width="29%"><strong>Presenter</strong></td>
                            <td style={{textAlign:'center',backgroundColor:'#e8e8e8'}} width="34%"><strong>Registration</strong></td>
                        </tr>

                        <tr className="alt">
                            <td style={{textAlign:'center'}}>August 12, 2025 4:00 pm (MT)</td>
                            <td style={{textAlign:'center'}}>Kristina van der Wal</td>
                            <td style={{textAlign:'center'}}>
                                <span style={{textDecoration: 'underline'}}>
                                    <span style={{color:'#ff0000',textDecoration:'underline'}}>
                                        <span style={{color:'#cc4037',textDecoration:'underline'}}>
                                            <a href="https://travelanceinc.clickmeeting.com/greatway-financial-international-student-plan/register" target="_blank" rel="noopener noreferrer">
                                                Click to Register
                                            </a>
                                        </span>
                                    </span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <div id="vtcmedical">
                <h3><span className="highlight">VTC Medical Eligibility Questionnaire (MEQ) Made Easier</span></h3>
                Review the VTC Medical Eligibility Questionnaire (MEQ) with your clients with confidence! This presentation provides you with in-depth knowledge of each eligibility question for our Visitors to Canada Emergency Medical
                Insurance plans.
                <p style={{fontSize:'14px',color:'#495677'}}>Please register for <span style={{textDecoration: 'underline'}}>one</span> of the following ‘VTC Medical Eligibility Questionnaire (MEQ) Made Easier’ sessions:</p>
                <table style={{height:'100px'}} border="1" width="100%">
                    <tbody>
                        <tr className="bg-color txt-center">
                            <td style={{textAlign:'center',backgroundColor:'#e8e8e8'}} width="37%"><strong>Date &amp; Time (MT)</strong></td>
                            <td style={{textAlign:'center',backgroundColor:'#e8e8e8'}} width="29%"><strong>Presenter</strong></td>
                            <td style={{textAlign:'center',backgroundColor:'#e8e8e8'}} width="34%"><strong>Registration</strong></td>
                        </tr>

                        <tr className="alt">
                            <td style={{textAlign:'center'}}>May 6, 2025 4:00 pm (MT)</td>
                            <td style={{textAlign:'center'}}>Kristina van der Wal</td>
                            <td style={{textAlign:'center'}}>
                                <span style={{textDecoration: 'underline'}}>
                                    <span style={{color:'#ff0000',textDecoration:'underline'}}>
                                        <span style={{color:'#cc4037',textDecoration:'underline'}}>
                                            <a href="https://travelanceinc.clickmeeting.com/greatway-financial-vtc-medical-eligibility-questionnaire-made-easier/register" target="_blank" rel="noopener noreferrer">
                                                Click to Register
                                            </a>
                                        </span>
                                    </span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />

            <div id="vtcmedical">
                <h3><span className="highlight">Travel Insurance – Value and Protection for Your Clients</span></h3>
                Discover the growing need for travel insurance for travelling Canadians and what Travelance can offer to protect your clients. By offering choices, Travelance offers a plan for almost everyone’s needs.
                <p style={{fontSize:'14px',color:'#495677'}}>Please register for <span style={{textDecoration: 'underline'}}>one</span> of the following ‘Travel Insurance – Value and Protection for Your Clients’ sessions:</p>
                <table style={{height:'100px'}} border="1" width="100%">
                    <tbody>
                        <tr className="bg-color txt-center">
                            <td style={{textAlign:'center',backgroundColor:'#e8e8e8'}} width="37%"><strong>Date &amp; Time (MT)</strong></td>
                            <td style={{textAlign:'center',backgroundColor:'#e8e8e8'}} width="29%"><strong>Presenter</strong></td>
                            <td style={{textAlign:'center',backgroundColor:'#e8e8e8'}} width="34%"><strong>Registration</strong></td>
                        </tr>

                        <tr className="alt">
                            <td style={{textAlign:'center'}}>June 24, 2025 4:00 pm (MT)</td>
                            <td style={{textAlign:'center'}}>Kristina van der Wal</td>
                            <td style={{textAlign:'center'}}>
                                <span style={{textDecoration: 'underline'}}>
                                    <span style={{color:'#ff0000',textDecoration:'underline'}}>
                                        <span style={{color:'#cc4037',textDecoration:'underline'}}>
                                            <a href="https://travelanceinc.clickmeeting.com/greatway-financial-travel-insurance-value-and-protection-for-your-clients/register" target="_blank" rel="noopener noreferrer">
                                                Click to Register
                                            </a>
                                        </span>
                                    </span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <div id="vtcmedical">
                <h3><span className="highlight">Student Accident Plan</span></h3>
                Did you know that expenses from most accidents exceed the benefits provided by your client’s government and group insurance plans? Learn how the Student Accident Plan offers families protection against financial
                responsibilities in the event of a child’s or student’s accident.
                <p style={{fontSize:'14px',color:'#495677'}}>Please register for <span style={{textDecoration: 'underline'}}>one</span> of the following ‘Student Accident Plan’ sessions:</p>
                <table style={{height:'100px'}} border="1" width="100%">
                    <tbody>
                        <tr className="bg-color txt-center">
                            <td style={{textAlign:'center',backgroundColor:'#e8e8e8'}} width="37%"><strong>Date &amp; Time (MT)</strong></td>
                            <td style={{textAlign:'center',backgroundColor:'#e8e8e8'}} width="29%"><strong>Presenter</strong></td>
                            <td style={{textAlign:'center',backgroundColor:'#e8e8e8'}} width="34%"><strong>Registration</strong></td>
                        </tr>

                        <tr className="alt">
                            <td style={{textAlign:'center'}}>September 30, 2025 4:00 pm (MT)</td>
                            <td style={{textAlign:'center'}}>Kristina van der Wal</td>
                            <td style={{textAlign:'center'}}>
                                <span style={{textDecoration: 'underline'}}>
                                    <span style={{color:'#ff0000',textDecoration:'underline'}}>
                                        <span style={{color:'#cc4037',textDecoration:'underline'}}>
                                            <a href="https://travelanceinc.clickmeeting.com/greatway-financial-student-accident-plan/register" target="_blank" rel="noopener noreferrer">Click to Register</a>
                                        </span>
                                    </span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />

            <p style={{paddingTop: '20px'}}>
                <a href="https://academy.travelance.ca/remote-login/login.cfm" target="_blank" rel="noopener noreferrer">Travelance Academy</a> - Our most flexible training option. Once you have completed the partnership process with
                Travelance, you will be able to access Travelance Academy through MyTL. Access on-demand, online learning, that you can complete on your own schedule. Looking for training for your team? Email
                <a href="mailto:training@travelance.ca">training@travelance.ca</a> to request a session.
            </p>

            <h2>Travelance Partner Resources</h2>
            To help you get started selling Travelance products, we’ve created resources to help you along every step of the way. Access complete policy wordings, rate charts, steps to selling guides, links to useful resources, and more!
            <a href="/partner/resources/" target="_blank" rel="noopener noreferrer">Click here to access our Partner Resources Webpage.</a>

            <h2>Contact Information</h2>
            For policy-related questions, changes or portal assistance contact Support Services, Monday to Friday, 9:00 am to 7:00 pm (ET), <a href="mailto:info@travelance.ca">info@travelance.ca</a> or 1-855-566-8555 extension 1.

            <iframe src="https://onedrive.live.com/embed?cid=31CF9DE968AACFA3&resid=31CF9DE968AACFA3%21113&authkey=AIoQhe0DGr4ePao&em=2" width="100%" height="410" frameBorder="0" scrolling="no" title="Slides"></iframe>


                </Col>
                <Col xl={4} className="">
                  <div className="stickyDiv">
                  <div className="holder_block">
                <h2>Quick Links</h2>
                <ul className="list">
                    <li><a href="/claims/" target="_blank" rel="noopener noreferrer">Claims</a></li>
                    <li><a href="https://www.getreliable.com/getreliable/" target="_blank" rel="noopener noreferrer">Online Portal Login</a></li>
                    <li><a href="/about-our-underwriter/" target="_blank" rel="noopener noreferrer">Our Underwriter</a></li>
                    <li><a href="/partner/faq-agents/" target="_blank" rel="noopener noreferrer">Partner FAQs</a></li>
                    <li><a href="/partner/online-training-sessions/" target="_blank" rel="noopener noreferrer">Register for Online Training</a></li>
                    <li><a href="https://www.travel.gc.ca/travelling/advisories/" target="_blank" rel="noopener noreferrer">Travel Advisories</a></li>
                </ul>
            </div>
                    </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer lang="en" />
    </>
  );
}

export default PartnerGficPage;
